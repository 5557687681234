@import "https://fonts.googleapis.com/css?family=MedievalSharp|Josefin+Sans";
#app {
  min-height: 100vh;
  max-width: 100vw;
  font-family: Josefin Sans, sans-serif;
  position: relative;
}

* {
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-button {
  display: none;
}

p, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
}

.page-layout-1 {
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding-bottom: 100px;
  display: flex;
  position: relative;
}

.page-layout-2 {
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 75px;
  display: flex;
  position: relative;
}

.hide-scroll {
  overflow-y: hidden;
}

.btn {
  text-align: center;
  text-shadow: none;
  color: #fff;
  background: linear-gradient(#0000, #00000080) top / 100% 800%;
  border: none;
  border-radius: 2px;
  align-items: center;
  text-decoration: none;
  transition: all .3s;
  display: flex;
  box-shadow: 0 2px 10px #00000029, 0 3px 6px #0000001a;
}

.btn:hover {
  color: #fff;
  cursor: pointer;
  background-position: bottom;
}

.btn-type-1 {
  padding: 2px 20px;
  font-weight: 600;
}

.btn-type-2 {
  margin: 5px;
  padding: 2px 18px;
  font-weight: 600;
}

.btn-type-3 {
  margin: 5px;
  padding: 1px 8px;
  font-weight: 500;
}

.btn-type-4 {
  margin: auto;
  padding: 1px 8px;
}

.btn-type-5 {
  padding: 1px 10px;
}

.btn-color-1, .btn-color-1:hover {
  background-color: #166696;
}

.btn-color-1-outline {
  color: #166696;
  background: linear-gradient(#0000, #16669666) top / 100% 800%;
  border: 2px solid #166696;
}

.btn-color-2, .btn-color-2:hover {
  background-color: #e4c900;
}

.btn-color-3, .btn-color-3:hover {
  background-color: #00a761;
}

.btn-color-4, .btn-color-4:hover {
  background-color: #f11e00;
}

.back-btn {
  z-index: 50;
  position: absolute;
  top: 15px;
  left: 40px;
}

.back-btn-2 {
  z-index: 50;
  position: absolute;
  top: 8px;
  left: 10px;
}

.close-btn {
  z-index: inherit;
  position: absolute;
  top: 8px;
  right: 10px;
}

.dashboard-close-btn {
  z-index: inherit;
  position: absolute;
  top: 2px;
  right: 10px;
}

.enable-pointer {
  pointer-events: auto;
}

.create-btn {
  position: absolute;
  top: 15px;
  right: 40px;
}

.create-btn-2 {
  position: absolute;
  top: 5px;
  right: 15px;
}

.tiny-btn {
  height: 12px;
  width: 25px;
  justify-content: center;
  margin-bottom: 1px;
  padding: 0;
  display: flex;
}

.unavailable {
  opacity: .3;
  cursor: default;
  pointer-events: none;
  background-color: #707070;
}

.plaintext-link {
  color: #60ccfb;
  text-decoration: none;
}

.plaintext-link-dark {
  color: #057fb3;
  text-decoration: none;
}

.plaintext-link:hover, .plaintext-link-dark:hover {
  text-decoration: underline;
}

.small-logo {
  height: 35px;
  margin-right: 7px;
}

.medium-logo {
  height: 40px;
  margin-right: 10px;
}

.large-logo {
  height: 90px;
}

.xl-logo {
  height: 120px;
}

.base-input {
  width: 50px;
  background-color: inherit;
  color: #040114;
  border: none;
  border-bottom: 1px solid #040114;
  border-radius: 0;
  outline: none;
  font-family: inherit;
}

.small-input {
  width: 40px;
  text-align: center;
  font-size: medium;
  font-weight: 700;
}

.vertical-form {
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 2px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.horizontal-form {
  width: 100%;
  text-align: center;
  flex-flow: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 15px;
  padding: 0 10px 20px;
  display: flex;
}

.form-header {
  height: 50px;
  width: 100%;
  color: #fff;
  background-color: #002d45;
  border-radius: 3px 3px 0 0;
  justify-content: center;
  align-items: center;
  font-family: MedievalSharp, sans-serif;
  display: flex;
  position: absolute;
  top: 0;
}

.form-piece {
  width: 100%;
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  position: relative;
}

.form-piece-split {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
  display: flex;
  position: relative;
}

.form-piece-split > p {
  margin: 0;
}

.form-dash {
  height: 2px;
  width: 5px;
  background-color: #040114;
}

.form-label {
  opacity: .6;
  color: #040114;
  cursor: text;
  font-size: 18px;
  transition: all .2s;
  position: absolute;
  top: 10px;
  left: 0;
}

.form-input:focus + .form-label, .form-input:valid:not(.empty-input) + .form-label {
  opacity: 1;
  font-size: 60%;
  font-weight: bold;
  transition: all .2s;
  top: 0;
}

.form-label-alt {
  color: #fff;
}

.hidden-label {
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.form-input {
  width: 100%;
  background-color: inherit;
  color: #040114;
  border: none;
  border-bottom: 1px solid #040114;
  border-radius: 0;
  outline: none;
  margin-bottom: 5px;
  padding: 5px 5px 4px 0;
}

.form-input:focus {
  border-bottom: 1px solid #01d99c;
}

.form-input-alt {
  color: #fff;
  border-bottom: 1px solid #fff;
}

select.form-input {
  height: 34px;
}

.form-input-split {
  width: 25%;
  position: relative;
}

.required:after {
  content: "*";
}

.collections-display {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 25px 0;
  margin: 25px 0;
  display: flex;
}

.collections {
  width: 450px;
  height: 200px;
  text-align: center;
  z-index: 2;
  color: #fff;
  text-shadow: 0 0 9px #000;
  border-radius: 3px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  font-weight: 600;
  display: flex;
  position: relative;
  box-shadow: 5px 10px 20px #00000029, 5px 15px 25px #0000001a;
}

.collections-img {
  width: 100%;
  height: 100%;
  opacity: .9;
  z-index: -1;
  border-radius: inherit;
  filter: brightness(80%);
  position: absolute;
}

.dashboard {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 25px;
  margin: 25px 0;
  display: flex;
}

.dashboard-item {
  min-height: 160px;
  width: 400px;
  text-align: center;
  border-bottom: 1px solid #166696;
  border-radius: 3px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 25px;
  padding: 50px 20px 10px;
  display: flex;
  position: relative;
  box-shadow: 0 0 15px #0000001a;
}

.dashboard-head {
  height: 40px;
  width: 100%;
  color: #fff;
  background-color: #166696;
  border-radius: 3px 3px 0 0;
  justify-content: center;
  align-items: center;
  font-family: MedievalSharp, sans-serif;
  display: flex;
  position: absolute;
  top: 0;
}

.breakdown-top {
  width: 80%;
  justify-content: center;
  align-items: center;
  gap: 25px;
  display: flex;
  position: relative;
}

.breakdown-base-info {
  min-height: 125px;
  width: 80%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

.breakdown-top-buttons {
  flex-direction: column;
  gap: 20px;
  display: flex;
  position: absolute;
  right: 0;
}

.breakdown {
  width: 85%;
  border-bottom: 3px solid #166696;
  border-radius: 3px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 5px;
  margin: 10px 0 25px;
  padding: 45px 20px 10px;
  display: flex;
  position: relative;
  box-shadow: 0 0 15px #0000001a;
}

.breakdown-head {
  height: 40px;
  width: 100%;
  color: #fff;
  background-color: #166696;
  border-radius: 5px 5px 0 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
}

.breakdown-piece {
  width: -moz-fit-content;
  width: fit-content;
  margin: 5px 0;
  padding: 20px 10px 0;
  position: relative;
}

.large-breakdown-piece {
  max-width: 90%;
  margin: 10px 5%;
}

.breakdown-label {
  position: absolute;
  top: .5px;
}

.breakdown-text {
  min-width: 200px;
}

.large-breakdown-piece > .breakdown-text {
  min-width: 0;
}

.breakdown-break {
  width: 90%;
  border-bottom: 2px solid #166696;
  margin: 0 5%;
}

.info-list-group {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 25px 0;
  margin: 20px 0 25px;
  display: flex;
}

.info-list-wrapper {
  width: 45%;
  height: -moz-fit-content;
  height: fit-content;
  border-bottom: 2px solid #166696;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 55px 0 15px;
  display: flex;
  position: relative;
  box-shadow: 0 0 15px #0000001a;
}

.info-list-full {
  width: 80%;
}

.info-list-head {
  height: 40px;
  width: 100%;
  color: #fff;
  background-color: #166696;
  border-radius: 5px 5px 0 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
}

.info-list-item {
  min-height: 30px;
  width: 85%;
  border-bottom: 1px solid #166696;
  margin: 5px 0;
  padding: 0 20px 5px;
}

.accordion {
  width: 90%;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.accordion-item {
  width: 100%;
  margin-bottom: 15px;
  padding-top: 50px;
  position: relative;
}

.accordion-item-header {
  height: 50px;
  width: 100%;
  cursor: pointer;
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  background-color: #166696;
  border-radius: 3px 3px 0 0;
  justify-content: center;
  align-items: center;
  font-family: MedievalSharp, sans-serif;
  font-weight: 700;
  display: flex;
  position: absolute;
  top: 0;
}

.accordion-content-wrapper {
  grid-template-rows: 0fr;
  transition: grid-template-rows 1s, padding 1s;
  display: grid;
}

.accordion-content-expanded {
  grid-template-rows: 1fr;
  padding: 15px 0 10px;
  box-shadow: 0 0 15px #0000001a;
}

.accordion-content-wrapper > div {
  overflow: hidden;
}

.accordion-content {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 10px;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  position: relative;
}

.accordion-item-status {
  background-color: inherit;
  color: inherit;
  border: none;
  font-weight: 700;
  position: absolute;
  right: 10px;
}

/*# sourceMappingURL=index.982d24d7.css.map */
